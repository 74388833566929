.ant-layout.site {
    background: #fff;
    padding:0;
    margin:0;
    .ant-layout-header {
        background: #fff;
        border-bottom: 1px solid #ddd;
        box-shadow: 0 0 5px 2px #eee;
        z-index: 999;
        padding:0;
        height:100px;
        .header {
            max-width: 1140px;
            margin: auto;
            .ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child{
                .ant-menu-title-content{
                    font-weight: bold;
                    color:#333;
                    a{
                        font-weight: bold;
                        color:#333 !important;
                    }
                } 
            }
            .ant-menu-overflow-item.ant-menu-submenu.ant-menu-submenu-horizontal{  
                .ant-menu-submenu-title{
                    .ant-menu-title-content{
                        font-weight: bold;
                        color:#333;
                        a{
                            font-weight: bold;
                            color:#333 !important;
                        }
                    } 
                }
            }

            .ant-menu-overflow-item.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child{
                .ant-menu-title-content{
                    font-weight: bold;
                    color:@primary-color !important;
                    a{
                        font-weight: bold;
                        color: @primary-color !important;
                    }
                } 
            }
            .ant-menu-overflow-item.ant-menu-submenu.ant-menu-submenu-horizontal.ant-menu-submenu-selected{
                .ant-menu-submenu-title{
                    .ant-menu-title-content{
                        font-weight: bold;
                        color:@primary-color !important;
                        a{
                            font-weight: bold;
                            color:@primary-color !important;
                        }
                    } 
                }
            }

            .logo {
                cursor: pointer;
                img {
                    margin:0 0 0 -5px;
                    width: 300px;
                }
            }
        }
    }
    .ant-layout{
        background: #fff;
    }
    .ant-layout-sider{
        background: #fff;
    }
    .ant-layout-content{
        background: #fff;
        margin:50px auto;
        width: 1140px !important;
        
    }
    .ant-layout-footer {
        padding:0;
        margin:0;
        border-top:3px solid #DDD;
        .infoWrapper{
            background-color: #E9E8E3;
            padding-top:40px;
            .info{
                width:1140px;
                margin:auto;
                .ant-card{
                    background-color: transparent;
                    .ant-card-head{
                        min-height: 0;
                        text-align: left;
                        font-weight: 500;
                        letter-spacing: 1px;
                        color:@primary-color;
                        border:none;
                        .ant-card-head-title{
                            padding: 0;
                        }
                    }
                    .ant-card-body{
                        padding-top:12px;
                        text-align: left;
                        font-weight: 400;
                        ul{
                        padding-inline-start: 0px;
                        }
                        iframe{
                            border:none;
                        }
                    }
                }

            }
        }
        .copyrightWrapper{
            background-color: #DDD;
            .copyright{
                padding:20px 0;
                width:1140px;
                margin:auto;
                color: #999;
                font-weight: 700;
                font-family: 'ROBOTO';
            }

        }
    }
    .actionsWrapper{
         .ant-col{
        padding:0;
        margin:12px 0 6px 0px;;
        line-height: 0;
        div{
            display: flex;
            justify-content : right;
            align-content: stretch;
            padding-right:20px;
            .ant-btn{
                margin-left:10px;
            }
            
        }
        }
    }
    .menuWrapper{
        .ant-menu {
            margin-top:0px;
            justify-content : right;
            align-content: stretch;
            background: #fff;
            border-bottom:none;
            line-height: 50px;
            
        }
    }
    .carouselPart{
        background-color: #E9E8E3;
        .carouselContent{
            width:1140px;
            margin:auto;    
            justify-content: center;
            align-items: center; 
        .carouselText{
            justify-content: center;
            align-items: center; 
            //background-color: #DDD;
            .carouselHighText{
                font-size: 38px;
                font-weight: 700;
                line-height: 42px;
                letter-spacing: 1px;
                color:@primary-color;
            }
            .carouselSubText{
                margin-top:10px;
            font-size: 20px;
            font-weight: 400;
            color:#555;
            }
        }
        .carouselImage1{
            height:420px;
            background: url(/assets/images/img1.png) ;
            background-position: 0px 60px;
        }
        .carouselImage2{
            height:420px;
            background:  url(/assets/images/img2.png) ;
            background-position: 0px 90px;
        }
        .carouselImage3{
            height:420px;
            background:   url(/assets/images/img3.png) ;
            background-position: 0px 60px;
        }
        }
    }
}
 


@primary-color: #0093AF;@link-color: #0093AF;@success-color: #00AFC1;@warning-color: #FFD124;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@layout-body-background: #f6f7fb;@layout-header-background: #272c33;@layout-header-height: 64px;@font-family: Roboto;@layout-sider-background: #f6f7fb;@menu-bg: #f6f7fb;@menu-inline-submenu-bg: #f6f7fb;@menu-item-active-bg: rgba(1, 1, 161, 0.05);@menu-item-active-border-width: 0;@menu-item-vertical-margin: 0;@menu-item-boundary-margin: 0;@tabs-card-head-background: #f6f7fb;@form-item-margin-bottom: 5px;